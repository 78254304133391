import React from 'react'
import { useState } from "react"; 
import LoanJS from "loanjs";



export default function LoanCalculator() {
    
    const [values,setValues] = useState({
       amount: '',
      initialamount: '',
      interest: '',
      years: '',  
    })
    
    const [installments, setInstallments] = useState([]);

    const handleInputChange =(event) =>{
        const {name,value} = event.target;

       setValues({
        ...values,
        [name]: value
       });
       
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        calculate(values["loan-amount"], 
        values["initial-amount"], 
        values["loan-term"], 
        values["interest-rate"]);
       
    };
  
      const [error1, setError1] = useState('');
      let actualError1 = ''; 
      actualError1 = 'Favor introducir un monto del prestamo mayor al inicial';
      const calculate = (amount,initialamount, years, rate) =>{  
      
      var amountFT = parseFloat(amount.replace(/,/g, ''));
      var initialamountFT = parseFloat(initialamount.replace(/,/g, ''));
      
      const amountinit = amountFT - initialamountFT;
      
      if(amountinit > 0){
      var Loan = new LoanJS.Loan(amountinit,years * 12,rate) 
        setInstallments(Loan.installments);
      }
      else{
       
        setError1(actualError1);
        
      }
      

    

    }
    

  
    const amountFormat = (amount) =>
    new Intl.NumberFormat("es-DO", {
        style: "currency",
        currency:"USD",
    }).format(amount);
{/** en-USD 
es-DO
*/}


const calculateResults = ({ priceproperty, startingpercentage, monthlypay }) => {

    var pricepropertyFT = parseFloat(priceproperty.replace(/,/g, ''));
    
    
    const calculatedinitialmonth = Number(pricepropertyFT) / 100 ;        
    const calculatedinitialmonthCalculated = calculatedinitialmonth * startingpercentage;     
    const monthlypaymentCalculated = calculatedinitialmonthCalculated / monthlypay;
    
    // Set up results to the state to be displayed to the user
    setResults({
      totalstartingpercentage: calculatedinitialmonthCalculated,
      monthlypayment: monthlypaymentCalculated,
      isResult: true,
    });
  
  return;
};


const handleSubmitValues = (e) => {
  e.preventDefault();
      if (isValid()) {
    setError('');
    calculateResults(userValues);
  }
};

const [results, setResults] = useState({
  totalstartingpercentage: '',
  monthlypayment: '',
  totalstartingpercentage: '',
  isResult: false,
});

const handleInputChangeinitial = (event) =>
setUserValues({ ...userValues, [event.target.name]: event.target.value });
const clearFields = () => {
setUserValues({
  pricepropertyFT: '',
startingpercentage: '',
monthlypay: '',
});

setResults({
totalstartingpercentage: '',
monthlypayment: '',
totalstartingpercentage: '',
isResult: false,
});
};

const [userValues, setUserValues] = useState({
pricepropertyFT: '',
startingpercentage: '',
monthlypay: '',

});
const [error, setError] = useState('');
const isValid = () => {
  const { priceproperty, startingpercentage, monthlypay } = userValues;

  
  var pricepropertyFT = parseFloat(priceproperty.replace(/-/g, ''));
  
  let actualError = '';
  // Validate if there are values
  {/*if (!priceproperty || !startingpercentage || !monthlypay) {
    actualError = 'All the values are required';
  }*/}
  // Validade if the values are numbers
  if (isNaN(pricepropertyFT) || isNaN(startingpercentage) || isNaN(monthlypay)) {
    actualError = 'Todos los valores deben ser un número válido';
  }
  // Validade if the values are positive numbers
  if (
    Number(pricepropertyFT) <= 0 ||
    Number(startingpercentage) <= 0 ||
    Number(monthlypay) <= 0
  ) {
    actualError = 'Todos los valores deben ser un número positivo.';
  }
  if (actualError) {
    setError(actualError);
    return false;
  }
  return true;
};
      

  return (
    
    <>

                                                <div className="col-md-8 col-sm-12">
                                          <div className="col-md-12 m-b10">
                                            <h4 className="text-uppercase font-weight-600 m-b10">Calcula tu inicial!</h4>
                                                    </div>
                                        <form className="contact-form cons-contact-form" onSubmit={handleSubmitValues}>
                                            <div className="contact-one m-b30"> 
                                                {/* TITLE START *  pattern="[0-9]{0,5}" /}
                                                
                                                {/* TITLE END */}
                                                <div className="col-md-4 col-sm-12 m-b30">
                                                <div className="form-group" >
                                                <label htmlFor="priceproperty">Precio del Inmueble:</label>
                                                    <input name="priceproperty" type="tel" className="form-control" required placeholder="USD$" 
                                                    
                                                    value={userValues.priceproperty}
                                                    onChange={handleInputChangeinitial} 
                                                    />
                                                  
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="initial-amount">Porcentaje Inicial:</label>  
                                                    <input name="startingpercentage"
                                                        type="tel" pattern="[0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|[0-9][0-9][0-9][0-9][0-9]|[0-9][0-9][0-9][0-9][0-9][0-9]" className="form-control" required placeholder="%"
                                                       
                                                        value={userValues.startingpercentage}
                                                    onChange={handleInputChangeinitial} 
                                                    />
                                                    
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="interest-rate">Plazo Inicial:</label>  
                                                    <input name="monthlypay" type="tel" pattern="[0-9]{0,5}" className="form-control" required placeholder="Meses" 
                                                    value={userValues.monthlypay}
                                                    onChange={handleInputChangeinitial} 
                                                    />
                                                </div>
                                               <p>Monto inicial: <input type='text' value={(amountFormat(results.totalstartingpercentage))} disabled /></p>
                                               
                                               <p>Cuotas Mensuale: <input type='text' value={(amountFormat(results.monthlypayment))} disabled /></p>
                                               
                                                <div className="text">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">Calcular </button><br/><br/>{error}
                                                </div>           
                                                </div>
                                                
                                            </div>
                                        </form>                                   
                                    </div>
                                        <div className="col-md-4 col-sm-12">
                                          <div className="col-md-12 m-b10">
                                            <h4 className="text-uppercase font-weight-600 m-b10">Calcula tu prestamo!</h4>
                                                    </div>
                                        <form className="contact-form cons-contact-form" onSubmit={handleSubmit}>
                                            <div className="contact-one m-b30"> 
                                                {/* TITLE START *  pattern="[0-9]{0,5}" /}
                                                
                                                {/* TITLE END */}
                                                <div className="col-md-8 col-sm-12 m-b30">
                                                <div className="form-group" >
                                                <label htmlFor="loan-amount">Monto del prestamo</label>
                                                    <input name="loan-amount" type="tel" className="form-control" required placeholder="USD$" 
                                                    
                                                    value={values["loan-amount"]}
                                                    onChange={handleInputChange} 
                                                    />
                                                  
                                                 
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="initial-amount">Monto del Inicial</label>  
                                                    <input name="initial-amount"
                                                        type="tel" /*pattern="[0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|[0-9][0-9][0-9][0-9][0-9]|[0-9][0-9][0-9][0-9][0-9][0-9]"*/ className="form-control" required placeholder="USD$"
                                                       
                                                        value={values["initial-amount"]}
                                                    onChange={handleInputChange} 
                                                    />
                                                    
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="interest-rate">Tasa de Interés</label>  
                                                    <input name="interest-rate" type="tel" pattern="[0-9]{0,5}" className="form-control" required placeholder="%" 
                                                    value={values["interest-rate"]}
                                                    onChange={handleInputChange} 
                                                    />
                                                </div>                                            
                                                <div className="form-group">
                                                <label htmlFor="loan-term">Tiempo (Años)</label>
                                                <input name="loan-term" type="tel" pattern="(0?[1-9]|[1-2][0-9]|30)" className="form-control" required placeholder="Años" 
                                                value={values["loan-term"]}
                                                onChange={handleInputChange} 
                                                />
                                                </div>
                                                <div className="text">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">Calcular</button>
                                                </div>
                                                
                                                </div>
                                            
                                            </div>
                                        </form>
                                    </div>
                               
                                    <div className="section-full p-tb80 inner-page-padding">
                            <div className="container">
                                <div className="row">
                                    
                                {!!installments.length && <div className="button-model col-md-3 col-sm-6 mt-separator-outer">
                                            {/* TRIGGER THE MODAL WITH A BUTTON */}
                                            <button className="m-b3" data-toggle="modal" data-target="#large-Modal">
                                                {/*<img src={require('./../../images/gallery/pic3.jpg').default} alt="" />*/}                                               
                                                <button className="m-b3 site-button outline blue text-uppercase m-r15" type="button">Resultados <i className='flaticon-chart'></i></button>                                                
                                            </button>
                                            {/* MODAL */}
                                            <div id="large-Modal" className="modal fade" role="dialog">
                                                <div className="modal-dialog modal-lg">
                                                    {/* MODAL CONTENT */}
                                                    <div className="modal-content">
                                                        <div className="modal-header bg-secondry">
                                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                                            <h4 className="modal-title text-white">PRESTAMO HIPOTECARIO</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                       
                                        <div className="section-content m-b50">
                                            
                                        <div id="no-more-tables">
                                        {!!installments?.length && <table className="table-striped table-condensed cf mt-responsive-table">
                                                <thead className="cf">
                                                    <tr>
                                                    <th>Mes</th>
                                                    <th>Monto del pago</th>
                                                    <th>Interest pagado</th>
                                                    <th>Pagado Principal</th>
                                                    <th>Restante</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {installments.map((item, index) => (
                                                        <tr key={index}>
                                                            <td data-title="Mes">{index}</td>
                                                            <td data-title="Monto del pago">{amountFormat(item.installment)}</td>
                                                            <td data-title="Interes pagado">{amountFormat(item.interest)}</td>
                                                            <td data-title="Pagado Principal">{amountFormat(item.capital)}</td>
                                                            <td data-title="Restante">{parseInt(item.remain)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>}
                                        </div>
                                        </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="site-button btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal">Cerrar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    
                                        </div>
                                        </div>
                                        </div>
                                                                                                            
              
</>
    
          
  )
}
