import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/4.jpg');

class Error extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                <Banner title="Error 404 - Página web no encontrada" pagename="Error 404" bgimage={bnrimg.default}/>
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb150">
                        <div className="container">
                            <div className="section-content">
                                <div className="page-notfound text-center">
                                    <strong>Pagina no encontrada</strong>
                                    <span className="title">404</span>
                                    <p>¡La página web que buscas no está aquí o se ha movido!</p>
                                    <NavLink to="/" title="Back to home" reloadDocument className="site-button btn-effect">Volver a Inicio</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default Error;