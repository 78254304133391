import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Specialization from './../Elements/Specialization';
import About from './../Elements/About';
import OurValue from './../Elements/OurValue';
import OurMission from './../Elements/OurMission';
import OurProject2 from './../Elements/OurProject2';
import Testimonials from './../Elements/Testimonials';
import Slider4 from '../Elements/Slider4';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider4 />
                    <Specialization />
                    <About />
                    <OurValue />
                    <OurMission />
                    {/*<OurServices />*/}
                    <OurProject2 />
                    {/** <Blogs />*/}
                    {/*<Testimonials />*/}
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;