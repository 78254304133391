import React from 'react';
import GoogleMaps from "simple-react-google-maps"
import { NavLink} from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import ContactUsForm from '../Elements/ContactUsForm';


var bnrimg = require('./../../images/banner/1.jpg');


class ContactUs extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                <Banner title="¡Poniendo un plan en acción, para asegurar su satisfacción!" pagename=" Contacto" bgimage={bnrimg.default}/>
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        <ContactUsForm/>
                                        {/*
                                        <form className="contact-form cons-contact-form" method="" action="">
                                            <div className="contact-one m-b30">
                                                {/* TITLE START 
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contacte con</span> Nosotros</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END 
                                                <div className="form-group">
                                                    <input name="username" type="text" required className="form-control" placeholder="Nombre" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" className="form-control" required placeholder="Correo electrónico" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} className="form-control " required placeholder="Mensaje" defaultValue={""} />
                                                </div>
                                                <div className="text-right">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">Enviar</button>
                                                </div>
                                            </div>
                                        </form>
                                        <ContactUsForm />
        */}
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Información de </span> Contacto</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Numero telefónico</h5>
                                                        <p>(809) 861-7096</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Correo electrónico</h5>
                                                        <p>info@coinca.com.do</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Dirección</h5>
                                                        <p>San isidro Brisa oriental II</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white text-black">
                                <div className="gmap-outline">
                                <GoogleMaps
                                apiKey={"AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0"}
                                style={{height: "400px", width: "100%"}}
                                zoom={12}
                                center={{lat: 18.494737, lng: -69.795810}}
                                markers={{lat: 18.494737, lng: -69.795810}} //optional
                                />
                                </div>
                                <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><a href={"https://www.facebook.com/coincasrl/"} target="_blank" rel="noreferrer noopener" className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><a href={"https://www.youtube.com/channel/UC-jqhMDyw2VwcZRrBvQwuZg"} target="_blank" rel="noreferrer noopener" className="fa fa-youtube" /></li>
                                            <li><a href={"https://www.instagram.com/coincasrl/"} target="_blank" rel="noreferrer noopener" className="fa fa-instagram" /></li>
                                        </ul>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default ContactUs;