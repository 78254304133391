import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

class Warranty extends React.Component {
    render() {
        return (
            <>

                <Header />
            <div className="page-content">
                    <Banner title="Proceso de garantia del inmueble adquirido." pagename="Garantia" bgimage={bnrimg.default}/>
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1 bg-gray  p-a50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-center">
                                        <div className="mt-separator">
                                            <h2 className="text-uppercase sep-line-one ">Garantia del inmueble</h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* Accordian */}
                                <div className="mt-accordion acc-bg-white" id="accordion5">
                                <div className="container">
                                <div className="row">
                            
                                        <div className="">
                                            <div className="m-b0">
                                                <ol className="list-num-count">
                                                    <li>El cliente procede a enviar un correo electrónico al correo info@coinca.com.do 
                                                        indicando las siguientes informaciones: proyecto, apartamento, teléfonos<br/> de contacto directo y correo electrónico.</li>
                                                    <li>Luego de dicha visita, y observación de la situación ocurrida, el ingeniero asignado le enviara una comunicación indicando el proceso a 
                                                        seguir para la <br/>resolución del problema, así como una fecha estimada de terminación.</li>
                                                    <li>Nuestra analista de Post Venta se encarga de responderle su correo electrónico, y asignarle una fecha de visita al apartamento, 
                                                        para fines de evaluar la <br/>situación y tomar las acciones de lugar.</li>
                                                    <li>Como paso final, el ingeniero asignado le proveerá luego de que la reclamación haya sido resuelta, un formulario que 
                                                        deberá completar a fines de dejar <br/>por terminadasu solicitud.</li>
                                                    <b> Es importante además informarles que las reclamaciones son recibidas por orden de fecha y urgencia, por lo cual la analista de 
                                                        dicho departamento le estará <br/>informando el orden luego de recibida la reclamación. Cualquier pregunta o duda pueden contactarnos 
                                                        a info@coinca.com.do o escribiéndonos al <br/>Whatsapp (809) 861-7096.</b>
                                                </ol>
                                            </div>
                                        </div>
                                </div>
                         </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

                <Footer />

            </>
        );
    };
};

export default Warranty;