import React from 'react';
import { NavLink } from 'react-router-dom';

class Faq extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Algunas preguntas</span> Frecuentes</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="section-content p-b30">
                                        <div className="mt-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel mt-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            Que opciones tengo en terminos de pago
                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse in">
                                                    <div className="acod-content p-tb15"><p> Puedes pagar el inmueble completo, u pagar un inicial de un 20% a un 40% y 
                                                        optar por un financiamiento de hasta un 80% con el banco de tu preferencia.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            Monto minimo para para hacer una separacion de inmueble
                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">Todos nuestros proyectos tienen un monto mínimo de separación 
                                                    para la firma del contrato de promesa de venta, de un 5% del valor del inmueble escogido.</div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                            Tengo que ir a la constructora cada vez que tengo que realizar un pago.
                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">No es necesario. Si se te complica venir a depositar, cuando separes tu inmueble te 
                                                    facilitamos un documento con las instrucciones para pagar tus cuotas vía transferencia, cheque o deposito a cuenta.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nuestra</span> Solución</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-gray center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-sketch" /></span>
                                                </div>
                                                <div className="icon-content text-black">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Asistencia</h4>
                                                    <p>Contáctanos para informaciones, solicitudes y/o reclamaciones de nuestros apartamentos y servicios.</p>
                                                    <NavLink to={"/contactus"} reloadDocument={true} className="site-button-link blue" data-hover="Read More">Contacto<i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-ui" /></span>
                                                </div>
                                                <div className="icon-content text-white">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Finanzas</h4>
                                                    <p>Con la siguiente calculadora puede obtener una estimación muy aproximada de la 
                                                        tasa a pagar por el inmueble que desee.</p>
                                                    <NavLink to={"/calculadora"} reloadDocument={true} className="site-button-link blue" data-hover="Read More">Calculadora<i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ */}
                </div>
            </>
        );
    }
};

export default Faq;