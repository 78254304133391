import React from 'react';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


export const ContactUsForm = () => {
  const form = useRef();
  
  const initialText = 'Enviar';
  const [buttonText, setButtonText] = useState(initialText);

  function handleClick() {
    setButtonText('Enviando...');

    setTimeout(() => {
      setButtonText(initialText);
    }, 2000); // change text back after 2 second
  }
  const sendEmail = (e) => {
    e.preventDefault();
    handleClick();
    emailjs.sendForm('service_r3pv76f', 'template_n7ul27r', form.current, 'd5ePYIspCrZATglgT')
      .then((result) => {
          document.getElementById("contacus").reset();
          console.log(result.text);
          console.log("mensaje enviado")
          alert(JSON.stringify("Mensaje enviado!"));
      }, (error) => {
          console.log(error.text);
          console.log("mensaje error")
      });
  };

  return (
    
    <>
      <form id="contacus" className="contact-form cons-contact-form" method="POST" action="" ref={form} onSubmit={sendEmail}>

              <div className="contact-one m-b30">
                  {/* TITLE START */}
                  <div className="section-head">
                      <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                              <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contacte con</span> Nosotros</h2>
                          </div>
                      </div>
                  </div>
                  {/* TITLE END */}
                  <div className="form-group">
                      <input name="user_name" type="text" required className="form-control" placeholder="Nombre" />
                  </div>
                  <div className="form-group">
                      <input name="user_email" type="email" className="form-control" required placeholder="Correo electrónico" />
                  </div>
                  <div className="form-group">
                      <textarea name="message" rows={4} className="form-control " required placeholder="Mensaje" defaultValue={""} />
                  </div>
                  <div className="text-right">
                      <button name="submit" id="button" type="submit" className="site-button btn-effect">{buttonText}</button>
                  </div>
               
              </div>
          </form></>
  );
};

export default ContactUsForm