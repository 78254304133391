import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "En Construccion", filter: ".cat-1" },
    { label: "Entregado", filter: ".cat-2" },
    { label: "Reformas", filter: ".cat-3" }
    
];

const projects = [
    {
        image: require('./../../images/projects/square/pic4.jpg'),
        title: 'Coinca II',
        category: 'En Construccion, Edificio',
        filter: 'cat-1',
        project: <NavLink to={"/coinca-II"} reloadDocument={true}></NavLink>
    },
    {
        image: require('./../../images/projects/coinca I/coincaI.jpg'),
        title: 'Caliope I',
        category: 'Entregado, Edificio',
        filter: 'cat-2',
        project: <NavLink to={"/caliope-I"} reloadDocument={true}></NavLink>
    }
    
    
]

class OurProject2 extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className="section-full p-t80 p-b30 square_shape2 mobile-page-padding">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nuestro</span> Proyectos</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="filter-wrap text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="Todos">Todos</NavLink></li>
                                    {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))}                            
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item ${item.width} col-md-4 col-sm-6 m-b30`}>
                                        
                                        <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src={item.image.default} alt="" />
                                            <div className="mt-info  p-t20 text-white">
                                                <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                                <p className="m-b0">{item.category}</p>
                                              
                                            </div>
                                            {item.project}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                        
                    </div>
                </div><div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Proyectos</strong>
                    </div>
            </>
        );
    }
};

export default OurProject2;