import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-5.png');

class Specialization extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1" style={{backgroundImage:"url(" + bnr1.default + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            <div className="project-detail-outer">
                            <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II cuarto.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II plano.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II sala.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>

                                <div className="col-lg-30 col-md-12 col-sm-6 m-b30">
                                    <div className="mt-box our-speciallization-content">
                                        <h3 className="m-t0"><span className="font-weight-100">Proyecto Residencial Coinca II</span> <br />Construyendo sueños reales.</h3>
                                        <p>Cuando se trata de tu hogar, confía en los mejores. Hacer realidad tu visión... eso es lo que hacemos.</p>
                                        <NavLink to="/coinca-II"  reloadDocument={true} className="site-button btn-effect">Ver Más</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
                        <strong>Bienvenido</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization;