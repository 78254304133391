import React from 'react';
import { NavLink } from 'react-router-dom';


 
class Navigation extends React.Component {

    

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className={(this.props.bgcolor !== '') ? `header-nav navbar-collapse collapse ${this.props.bgcolor}` : 'header-nav navbar-collapse collapse'}>
                    <ul className="nav navbar-nav">
                        <li className="active">
                            <NavLink to={"/"} reloadDocument={true}>Inicio</NavLink>
                        </li>

                        <li>
                        <NavLink to={"#"} >Proyectos</NavLink>
                        <ul className="sub-menu">
                                <li>
                                <NavLink to={"#"}>Proyectos entregados</NavLink>
                                    <ul className="sub-menu has-child">
                                        <li><NavLink to='/caliope-I' reloadDocument={true}>Caliope I</NavLink></li>                                                               
                                    </ul>
                                </li>
                                <li>
                                <NavLink to={"#"}>Proyectos en construccion</NavLink>
                                    <ul className="sub-menu has-child">
                                        <li><NavLink to={"/coinca-II"} reloadDocument={true}>Coinca ll</NavLink></li>                                                               
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li>
                        <NavLink to={"#"} >Servicios</NavLink>
                                <ul className="sub-menu">
                                <li>
                               <NavLink to={"/services"} reloadDocument={true}>Nuestros Servicios</NavLink>
                                </li>
                                <li>
                                <NavLink to={"/calculadora"} reloadDocument={true}>Calculadora de prestamo & Inicial</NavLink>
                                </li>
                                <li>
                                <NavLink to={"/faq"} reloadDocument={true}>Preguntas Frecuentes</NavLink>
                                </li>
                                <li>
                                <NavLink to={"/warranty"} reloadDocument={true}>Garantia del inmueble</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                        <NavLink to={"/about"} reloadDocument={true}>Sobre Nosotros</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/contactus"} reloadDocument={true}>Contacto</NavLink>
                        </li>
                    </ul>
                </div>
            </>
        );
    };
};

export default Navigation;