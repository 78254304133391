import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

class Faq extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                <Banner title="​​​​​​​​Preguntas frecuentes sobre nuestros servicios en el area inmobiliaria" pagename="Preguntas Frecuentes" bgimage={bnrimg.default}/>
                    
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1 bg-gray  p-a50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-center">
                                        <div className="mt-separator">
                                            <h2 className="text-uppercase sep-line-one ">Preguntas Frecuentes</h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* Accordian */}
                                <div className="mt-accordion acc-bg-white" id="accordion5">
                                    <div className="panel mt-panel">
                                        <div className="acod-head acc-actives">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                ¿Cómo trabajamos?
                                                <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne5" className="acod-body collapse in">
                                            <div className="acod-content p-tb15">
                                            <p> Vendemos proyectos en planos y le damos un plazo al cliente de pagar su inicial por cuotas y luego le ayudamos con el financiamiento con el banco de su preferencia.</p>
              </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                ¿Qué servicios ofrecemos?
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo5" className="acod-body collapse">
                                            <div className="acod-content p-tb15"><p>Construcción y ventas de inmuebles.</p></div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                ¿Si vivo en el exterior, puedo comprar un apartamento y gestionar el financiamiento?
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree5" className="acod-body collapse">
                                            <div className="acod-content p-tb15"><p>Si vives en el exterior, nuestros representantes de ventas se encargaran de ayudarte en todo el proceso de compra de la unidad de tus sueños, mediante el diseño de un plan de pago y gestión de financiamiento con entidades locales. Si quieres saber más, favor escribirnos.</p></div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                ¿Cuál es el monto mínimo para realizar una reservación de un inmueble?
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFour5" className="acod-body collapse">
                                            <div className="acod-content p-tb15"><p>Todos nuestros proyectos tienen un monto mínimo de reservación de RD$100,000.00. A partir de la reserva, el cliente tiene 30 días para firmar el contrato de promesa de venta y completar el monto de separación a la firma de dicho documento.</p></div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                ¿Cómo hago para visitar un apartamento modelo del proyecto que me interesa?
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                            <p>Constructora Coinca tiene sus oficinas de ventas localizadas en la, donde uno de nuestros representantes le atenderá. También puede hacer una cita para visitar los apartamentos modelos llamando al (809) 728-2462 y (809) 861-7096, o escribiéndonos a info@coinca.com.do</p>
              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default Faq;