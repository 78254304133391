import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import LoanCalculator from './LoanCalculator';


var bnrimg = require('./../../images/services/calculator/pasos-calcular-intereses-prestamo.jpg');

class Calculator extends React.Component {
   
    
    render() {

        return (
            <>
            
                <Header />
                <div className="page-content">
                <Banner title="Calculadora de prestamo" pagename=" Calculadora" bgimage={bnrimg.default}/>
                   
                    {/* SECTION CONTENTG START <span className="title">Calculadora</span> */}
                    <div className="section-full p-tb30">
                        <div className="container">
                        <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Calculadora de</span> Inicial & prestamo </h2>
                                    </div>
                                </div>
                            </div>                                    
                                    <LoanCalculator/>     
                        </div>
                    </div>
                    
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />

            </>
        );
    };
};

export default Calculator;