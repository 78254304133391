import React from 'react';
import { NavLink,Link } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import GoogleMaps from "simple-react-google-maps"

var bnrimg = require('./../../images/banner/11.jpg');

class ProyectocoincaII extends React.Component {
    
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="¡Poniendo un plan en acción, para asegurar su satisfacción!" pagename="Proyecto Coinca II" bgimage={bnrimg.default}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II cuarto.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II plano.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/coinca II/coinca II sala.jpg').default} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h2 className="text-black text-uppercase sep-line "><span className="font-weight-300 text-primary">  </span>Coinca ii</h2>
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Fecha</h4>
                                                        <p>Diciembre, 2022</p>
                                                    </div>
                                                   
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Tipo de Proyecto</h4>
                                                        <p>Edificio Residencial</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Localización</h4>
                                                        <p>Brisa Oriental</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Año</h4>
                                                        <p>2022</p>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                                                <img src={require('./../../images/projects/coinca II/coincaIIvid.jpg').default} alt="coinca I"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='zycor_Z5Stc' onClose={() => this.setState({ isOpen: false })} />
                                                    <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>
                                                    
                                                </div>
                                            </div><div className="section-content"><a href={"https://drive.google.com/file/d/1JC2frPngfohQQ2oNfJJbwHBc8MSy4Up3/view"} target="_blank" rel="noreferrer noopener">
                                     <button className="mm-b15 site-button btn-effect m-r15 text-uppercase" type="button">DISPONIBILIDAD</button></a>
                                    </div>
                                        </div>

                                    </div> 
                                   
                                </div>
                                
                                <div className="project-detail-containt">

                                
                                <div className="section-content">
                                                <div className="mt-tabs bg-tabs">
                                                    <ul className="nav nav-tabs">
                                                        <li className="active"><a data-toggle="tab" href="#caracteristica" ><div className="icon-fonts-block"> Caracteristicas <a className="flat-icon flaticon-project"></a></div></a></li>
                                                        <li><a data-toggle="tab" href="#terminacion">  <div className="icon-fonts-block">TERMINACIONES <a className="flat-icon flaticon-decorating"></a></div></a></li>
                                                        {/*<li><a data-toggle="tab" href="#developement-13">Massages</a></li>*/}
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="caracteristica" className="tab-pane active">
                                                        <div className="section-content">
                                                            <ul className="list-simple p-l15">
                                                                <li>Sala.</li>
                                                                <li>Comedor.</li>                                                
                                                                <li>Cocina con desayunador</li>
                                                                <li>Área de lavado.</li>
                                                                <li>3 Habitaciones.</li>
                                                                <li>Habitación principal con baño y W/C.</li>
                                                                <li>2 Baños.</li>
                                                                <li>1 y 2 Parqueos.</li>
                                                                <li>Balcón.</li>
                                                                <li>Terraza privada para 4to nivel.</li>
                                                                <li>Área común.</li>
                                                                <li>Intercom.</li>
                                                                <li>Portón eléctrico.</li>
                                                                <li>Cisterna.</li>
                                                                <li>Agua permanente.</li>
                                                                <li>Pre-instalación: Inversor y aire acondicionado.</li>
                                                                <li>Cañería y griferia europea.</li>
                                                            </ul>
                                                        </div>
                                                          
                                                        </div>
                                                        <div id="terminacion" className="tab-pane">
                                                        <div className="section-content">
                                                            <ul className="list-simple p-l15">
                                                                <li>Piso porcelanato importado.</li>
                                                                <li>Techo en yeso.</li>
                                                                <li>Madera preciosa.</li>
                                                                <li>Tope en granito natural.</li>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    <div className="bg-white text-black">
                                       {/*} <h3 className="font-weight-600">Crear un futuro sostenible a través de la conservación de edificios, la arquitectura ecológica y el diseño inteligente</h3>
                                        <p> Los diseñadores piensan que todo lo que hace otra persona es horrible y que ellos mismos podrían hacerlo mejor, lo que explica por qué diseñé mi propia alfombra para la sala, supongo. el arquitecto no representa una condición ni dionisiaca ni apoliniana: aquí está el poderoso acto de la voluntad, la voluntad que mueve montañas, la embriaguez de la voluntad fuerte, la que exige la expresión artística. Los hombres más poderosos siempre han inspirado a los arquitectos; el arquitecto siempre ha estado influenciado por el poder.</p>
                                       {/* <p>Tincidunt integer euaugue augue nunc elit dolor luctus placerat scelerisque euismod lorem. Utenim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Tincidunt integer eu augue augue nunc elit dolor, luctus placerat scelerisque euismod, iaculis eu lacus nunc mi elite sit amet justo nunc tempore. Lorem ipsum dolor sit amet consectetur adipiscing elitsed do eiusmod tempor incididunt ut ore magna aliqua. Arullamco laboris nisi ut aliquip ex ea commodo. Tincidunt integer eu augue augue nunc elit dolor, luctus placerat scelerisque euismod, iaculis eu lacus nunc mi elite sit amet justo nunc tempore. Lorem ipsum dolor sit amet consectetur adipiscing elitsed do eiusmod tempor incididunt ut ore magna aliqua. </p>*/}
                                       <div className="gmap-outline">
                                        <GoogleMaps
                                        apiKey={"AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0"}
                                        style={{height: "400px", width: "100%"}}
                                        zoom={12}
                                        center={{lat: 18.491706338243272, lng: -69.7928002898901}}
                                        markers={{lat: 18.491706338243272, lng: -69.7928002898901}} //optional
                                        />
                                        </div>
                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><a href={"https://www.facebook.com/coincasrl/"} target="_blank" rel="noreferrer noopener" className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><a href={"https://www.youtube.com/channel/UC-jqhMDyw2VwcZRrBvQwuZg"} target="_blank" rel="noreferrer noopener" className="fa fa-youtube" /></li>
                                            <li><a href={"https://www.instagram.com/coincasrl/"} target="_blank" rel="noreferrer noopener" className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  <SimilarProjects/>*/}
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default ProyectocoincaII;