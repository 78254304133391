import React from 'react';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';



export const ContactHomeForm = () => {
  const form = useRef();
  
  const initialText = 'Enviar';
  const [buttonText, setButtonText] = useState(initialText);

  function handleClick() {
    setButtonText('Enviando...');

    setTimeout(() => {
      setButtonText(initialText);
    }, 2000); // 👈️ change text back after 1 second
  }
  const sendEmail = (e) => {
    e.preventDefault();
    handleClick();
    emailjs.sendForm('service_r3pv76f', 'template_xhepdub', form.current, 'd5ePYIspCrZATglgT')
      .then((result) => {
          document.getElementById("contac").reset();
          console.log(result.text);
          console.log("mensaje enviado")
          alert(JSON.stringify("Mensaje enviado!"));
      }, (error) => {
          console.log(error.text);
          console.log("mensaje error")
      });
  };
 


  return (
    
    <>
       <form id="contac" className="cons-contact-form2 form-transparent" ref={form} onSubmit={sendEmail}>
                     <div className="input input-animate">
                         <label htmlFor="name">Nombre</label>
                        <input type="text" name="user_name" id="name" required />
                        <span className="spin" />
                    </div>
                    <div className="input input-animate">
                         <label htmlFor="email">Correo electrónico</label>
                        <input type="email" name="user_email" id="email" required />
                        <span className="spin" />
                    </div>
                    <div className="input input-animate">
                         <label htmlFor="Phone">Teléfono</label>
                        <input type="text" name="user_phone" id="Phone" required />
                        <span className="spin" />
                    </div>
                    <div className="input input-animate">
                         <label htmlFor="message">Mensaje</label>
                        <textarea name="message" id="message" required defaultValue={""} />
                        <span className="spin" />
                    </div>
                    <div className="text-center p-t10">
                         <button type="submit" id="submit" name="submit" className="site-button btn-effect ">
                             {buttonText}
                     </button>
                    </div>
                </form></>
  );
};

export default ContactHomeForm