import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Faq from './Pages/Faq';
import Error from './Pages/Error';
import ContactUs from './Pages/ContactUs';
import Calculator from './Pages/Calculator';
import ProyectocoincaI from './Pages/Proyecto coinca I';
import ProyectocoincaII from './Pages/Proyecto coinca II';
import Warranty from './Pages/Warranty';
import RenovatedHouse from './Pages/Renovated House';


class Components extends React.Component {
    render() {
        return (
            
            
            <BrowserRouter basename="/">
                <div className="page-wraper">
                        <Routes>
                            <Route path='/' element={<Home/>} />
                            <Route path='/index.html' element={<Home/>} />
                            
                            <Route path='/about' element={<About/>} />
                   
                            <Route path='/services' element={<Services/>} />
                            <Route path='/calculadora' element={<Calculator/>} />
                            <Route path='/faq' element={<Faq/>} />
                            <Route path='/warranty' element={<Warranty/>} />

                            <Route path='/error' element={<Error/>} />
                            <Route path='/contactus' element={<ContactUs/>} />

                            <Route path='/caliope-I' element={<ProyectocoincaI/>} />
                            <Route path='/coinca-II' element={<ProyectocoincaII/>} />
                            <Route path='/renovatedhouse' element={<RenovatedHouse/>} />
                            
                            
                            <Route path='*' element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
            
        );
    };
};

export default Components;