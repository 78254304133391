import React from 'react';
import { NavLink } from 'react-router-dom';
import ContactHomeForm from './ContactHomeForm';

var img1 = require('./../../images/left-men.png');
var img2 = require('./../../images/background/bg-4.png');
var img3 = require('./../../images/background/bg-site.png');
var img4 = require('./../../images/mission.jpg');

class OurMission extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding mission-outer-section  p-t80 p-b30 bg-gray bg-no-repeat bg-right-center" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nuestra</span> Misión</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="mission-left bg-white m-b30 p-a30 bg-no-repeat bg-bottom-left" style={{ backgroundImage: 'url(' + img3.default + ')' }}>
                                        <h3 className="m-t0"><span className="font-weight-100"> Áreas </span><br /> de Servicios</h3>
                                        <p>A continuación se enumeran algunas de ellas:</p>
                                        <ul className="list-angle-right anchor-line">
                                            <li><NavLink to="/about">Gestión de la construcción</NavLink></li>
                                            <li><NavLink to="/about">Ventas de los inmuebles.</NavLink></li>
                                            <li><NavLink to="/about">Administracion de los inmuebles.</NavLink></li>
                                            <li><NavLink to="/about">Asesoria inmobiliaria.</NavLink></li>
                                            {/*<li><NavLink to="/about">Implementación</NavLink></li>*/}
                                        </ul>
                                        {/*<div className="text-right">
                                            <NavLink to="/about" className="site-button-link" data-hover="Read More">Leer mas <i className="fa fa-angle-right arrow-animation" /></NavLink>
        </div>*/}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="mission-mid bg-no-repeat bg-cover m-b30" style={{ backgroundImage: 'url(' + img4.default + ')' }} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="contact-home1-left bg-dark p-a30 m-b0">
                                        <h3 className="text-white m-t0"><span className="font-weight-100">Contacte con</span> Nosotros</h3>
                                       <ContactHomeForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Misión</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default OurMission;